import React from 'react';
import './Homepage.css';
import GithubOverview from '../../components/GithubOverview';
import {
    Text,
    Heading,
    Link,
    //Button, Spinner
} from '@primer/react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Homepage = () => {

    var username = "hikkoiri"

    return (
        <>
            <div className="centered-box">
                <div className="inner-box">

                    <Heading>Welcome</Heading>
                    <br />

                    <Text as="p" style={{ margin: "0 0 0 0" }}>
                        Hi,
                        my name is Carlo.
                        <br />
                        I am a tech loving generalist born in the cloud with broad experience from application development to IT strategy consulting who is currently living in the South German capital of automotive world-class engineering, Stuttgart.
                        <br />
                        Feel free to to get in contact with me
                        <Link href="https://www.linkedin.com/in/carlo-hildebrandt/" sx={{ fontWeight: 'bold', color: 'fg.default', mr: 1 }} muted>
                            &nbsp;via Linkedin.
                        </Link>
                        <br />
                        With ♥️ Carlo
                    </Text>

                    <br />
                    <br />




                    <div>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls=""
                                id="panel1-header"
                            >
                                <Text>
                                    Side projects
                                </Text>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Text>
                                    Here is a growing list of personal projects I've been working on night and day.
                                    These are services hosted by me that are free-to-use, so give them a visit:

                                    <ul>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href="https://wennwuenschewahrwerden.com/">Wenn Wünsche wahr werden</a> - Want to secretly share with your friends and family what you are wishing yourself for christmas? (German only & closed beta)
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href="https://walk-together.carlo-hildebrandt.de/">Walk together</a> - A way to track your daily step count together with your friends. Similar to the VirginPulse challenges, remember these?
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href="https://oishi.carlo-hildebrandt.de/">Oishi</a> - Use that site to perform blind-tastings with your friends, submit your ratings and see what others think in realtime. Presets for wine and beer tastings already exist. If you want more, contact me.
                                        </li>
                                    </ul>
                                </Text>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls=""
                                id="panel3-header"
                            >
                                <Text>
                                    Public Github Repositories
                                </Text>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Text>
                                    Of course I also code and don't want to withhold some of my personal (non work-related) Github projects from you:
                                </Text>
                                <br /><br />
                                <GithubOverview
                                    username={username}
                                />
                                <br />

                            </AccordionDetails>
                        </Accordion>
                        <br />
                        <a href="https://hits.seeyoufarm.com"><img alt="Page visit counter" src="https://hits.seeyoufarm.com/api/count/incr/badge.svg?url=https%3A%2F%2Fcarlo-hildebrandt.de&count_bg=%2379C83D&title_bg=%23555555&icon=&icon_color=%23E7E7E7&title=Page+visits+%28daily+%2F+total%29&edge_flat=false" /></a>                    </div>
                </div>
            </div>
        </>);

};

export default Homepage;